import ContactForm from "../ContactForm/ContactForm";

const Contact = () => {
    return (
        <section className="contact-ctn" id="Contact">
            <ContactForm />
        </section>
    );
};

export default Contact;
